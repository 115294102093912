exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-action-tsx": () => import("./../../../src/pages/action.tsx" /* webpackChunkName: "component---src-pages-action-tsx" */),
  "component---src-pages-ceasefire-cities-tsx": () => import("./../../../src/pages/ceasefire-cities.tsx" /* webpackChunkName: "component---src-pages-ceasefire-cities-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-gaza-solidarity-encampments-tsx": () => import("./../../../src/pages/gaza-solidarity-encampments.tsx" /* webpackChunkName: "component---src-pages-gaza-solidarity-encampments-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-not-just-numbers-tsx": () => import("./../../../src/pages/not-just-numbers.tsx" /* webpackChunkName: "component---src-pages-not-just-numbers-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-protests-tsx": () => import("./../../../src/pages/protests.tsx" /* webpackChunkName: "component---src-pages-protests-tsx" */)
}

